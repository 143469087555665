import styled from 'styled-components'

export const Card = styled.div`
  background-color: var(--color-background-card);
  margin: 0 15px 15px 15px;
  border-radius: 20px;
  color: var(--color-font);
  box-shadow: 2px 2px 2px 1px rgba(0, 0, 0, 0.2);
  max-width: 100%;
  max-width: 83vw;
  position: relative;

  @media (min-width: 500px) {
    max-width: 89vw;
  }

  @media (min-width: 800px) {
    max-width: 92vw;
  }

  @media (min-width: 1000px) {
    max-width: 95vw;
  }
`
