import { useFormik } from 'formik'
import { useEffect, useState } from 'react'
import { useHistory, useParams } from 'react-router-dom'
import {
  handleGetPowerBiCapacity,
  handleUpdatePowerBiCapacity,
} from 'services/api/powerbi'
import * as Yup from 'yup'
import Component from './Component'
import { optimizeCostsValidation } from './validation'

const Page = () => {
  const history = useHistory()
  const params = useParams()
  const [messages, setMessages] = useState({ messages: [], alert: '' })
  const [data, setData] = useState({})

  useEffect(() => {
    ;(async () => setData(await handleGetPowerBiCapacity(params.id)))()
  }, [params.id])

  const formik = useFormik({
    initialValues: {
      optimize_cost: data.optimize_cost,
      idle_suspend_period: data.idle_suspend_period,
    },
    validationSchema: Yup.object(optimizeCostsValidation(Yup)),
    onSubmit: ({ idle_suspend_period, optimize_cost }) => {
      handleUpdatePowerBiCapacity(
        params.id,
        {
          idle_suspend_period: !!Number(optimize_cost)
            ? idle_suspend_period
            : 0,
          optimize_cost: !!Number(optimize_cost),
        },
        setMessages,
        history,
      )
    },
    enableReinitialize: true,
  })

  return (
    <Component
      formik={formik}
      messages={messages}
      setMessages={setMessages}
      history={history}
      data={data}
      id={data.id}
    />
  )
}

export default Page
