import { useFormik } from 'formik'
import { useEffect, useState } from 'react'
import { FaEdit } from "react-icons/fa"
import { RiSubtractLine } from "react-icons/ri"
import { useParams } from 'react-router'
import Swal from "sweetalert2"

import BtnBox from 'components/Button/BtnBox'
import BtnOrange from 'components/Button/BtnOrange'
import BtnRed from 'components/Button/BtnRed'
import BtnWhite from 'components/Button/BtnWhite'
import LinkBlue from 'components/Button/LinkBlue'
import SmLinkBlue from 'components/Button/SmLinkBlue'
import Card from 'components/Card'
import CardBody from 'components/CardBody'
import CardTitle from 'components/CardTitle'
import Content from 'components/Content'
import Checkbox from 'components/Form/LabelCheckbox'
import Modal from 'components/Modal'
import Table from 'components/Table'
import TitleBar from 'components/TitleBar'

import { handleReportsByUserWithCardWithoutGroups } from 'services/api/reports'
import { handleUpdateViewReportOptions, handleUserShow } from 'services/api/users'

import api from 'services/api/api'

const Page = () => {
    const [data, setData] = useState([])
    const [user, setUser] = useState({})

    const [userReportsCollections, setUserReportsCollections] = useState([])
    const [visibleModal, setVisibleModal] = useState(false)

    const [collectionSelectedId, setCollectionSelectedId] = useState()

    const params = useParams()

    const formik = useFormik({
      initialValues: {
        view_report_options: !!user.view_report_options,
        restricted_login: !!user.restricted_login
      },
      enableReinitialize: true
    })

    useEffect(() => {
        (async () => {
            setUser(await handleUserShow(params.id))
            setData(await handleReportsByUserWithCardWithoutGroups(params.id))
        })()
    }, [params.id])

    useEffect(() => {
        loadUserReportsCollections()
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const loadUserReportsCollections = async () => {
        try {
            const { data } = await api.get(`/users-collections/${params.id}`)

            setUserReportsCollections(data)
        } catch(error) {

        }
    }

    const handleDelete = async () => {
        try {
            await api.delete(`/users-collections/${collectionSelectedId}`)

            loadUserReportsCollections()
            setVisibleModal(false)
        } catch(error) {
        }
    }

    const handleSetViewReportOptions = async () => {
      const viewReportOptions = !formik.values.view_report_options ? 1 : 0

      try {
        await handleUpdateViewReportOptions({
          view_report_options: viewReportOptions
        }, user.id)

        Swal.fire(
          'Sucesso',
          'Atualização efetuada com sucesso!',
          'success'
      )
      } catch(error) {
        console.log(error);
        Swal.fire(
          'Erro',
          'Houve um problema ao atualizar esta opção!',
          'error'
      )
      }
    }

    return (
        <>
            <TitleBar label="Usuários" currentPage="" />

            <Content>
                <Card>
                    <CardTitle title="Usuário" />

                    <Table>
                        <thead>
                        <tr>
                            <th>Nome</th>
                            <th>Email</th>
                            <th>Permissão</th>
                            <th>Visualizar opções</th>
                            {/* <th>Login restrito</th> */}
                        </tr>
                        </thead>
                        <tbody>
                        <tr>
                            <td>{user.name}</td>
                            <td>{user.email}</td>
                            <td>{user.roles}</td>

                            <td style={{ paddingLeft: window.innerWidth <= 600 ? "2rem" : "3rem" }}>
                              {user.id && <Checkbox onClick={handleSetViewReportOptions} name='view_report_options' label='' formik={formik} checked={user.view_report_options} />}
                            </td>

                            {/* <td style={{ paddingLeft: window.innerWidth <= 600 ? "2rem" : "3rem" }}>
                              {user.id && <Checkbox onClick={handleSetRestrictedLogin} name='restricted_login' label='' formik={formik} checked={user.restricted_login} />}
                            </td> */}
                        </tr>
                        </tbody>
                    </Table>
                </Card>

                <Card>
                    <CardTitle title="Relatórios">
                        <LinkBlue to={`/usuarios/${params.id}/relatorios/cadastrar`}>Cadastrar</LinkBlue>
                    </CardTitle>

                    <Table>
                        <thead>
                            <tr>
                                <th width="60">#</th>
                                <th>Relatório</th>
                                <th>Card</th>
                                <th>Roles</th>
                                <th>Abas habilitadas</th>
                                <th width="50">Ações</th>
                            </tr>
                        </thead>

                        <tbody>
                            {data.map(item =>
                                <tr key={item.id}>
                                    <td>{item.id}</td>
                                    <td>{item.name}</td>
                                    <td>{item.card_name}</td>
                                    <td>{item.roles}</td>
                                    <td>{item.page_navigation ? 'Sim' : 'Não'}</td>

                                    <td>
                                        <SmLinkBlue to={`/usuarios/${params.id}/relatorios/${item.id}/editar`}>
                                            <FaEdit />
                                        </SmLinkBlue>
                                    </td>
                                </tr>
                            )}
                        </tbody>
                    </Table>
                </Card>

                <Card>
                    <CardTitle title="Grupos">
                        <LinkBlue to={`/usuarios/${params.id}/colecoes/cadastrar`}>Cadastrar</LinkBlue>
                    </CardTitle>

                    <Table>
                        <thead>
                            <tr>
                                <th width="60">#</th>
                                <th>Nome</th>
                                <th width="50">Ações</th>
                            </tr>
                        </thead>

                        <tbody>
                            {userReportsCollections.map(userReportsCollection => (
                                <tr key={userReportsCollection.id}>
                                    <td>{userReportsCollection.id}</td>
                                    <td>{userReportsCollection.users_group.name}</td>

                                    <td >
                                        <BtnOrange style={{ padding: '5px 20px' }} onClick={() => {
                                            setVisibleModal(true)
                                            setCollectionSelectedId(userReportsCollection.id)
                                        }}>
                                            <RiSubtractLine />
                                        </BtnOrange>
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </Table>
                </Card>
            </Content>

            <Modal visibleModal={visibleModal} setVisibleModal={setVisibleModal}>
                <Card>
                    <Content>
                    <Content>
                        <CardBody>
                        <p>Deseja remover esta coleção?</p>

                        <BtnBox>
                            <BtnRed onClick={handleDelete}>Excluir</BtnRed>
                            <BtnWhite onClick={() => setVisibleModal(false)}>Cancelar</BtnWhite>
                        </BtnBox>
                        </CardBody>
                    </Content>
                    </Content>
                </Card>
            </Modal>
        </>
    )
}

export default Page
