import { useEffect, useState } from 'react'

import { handleGetPowerBiCapacities } from 'services/api/powerbi'

import Component from './Component'

const Page = () => {
  const [data, setData] = useState([])

  useEffect(() => {
    ;(async () => {
      try {
        setData(await handleGetPowerBiCapacities())
      } catch (error) {}
    })()
  }, [])

  return <Component data={data} />
}

export default Page
