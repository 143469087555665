import { useFormik } from 'formik'
import { useState } from 'react'
import { useHistory, useParams } from 'react-router-dom'
import { handleUserLogin } from 'services/api/users'
import * as Yup from 'yup'
import Component from './Component'
import Validation from './validation'

const Page = ({ data }) => {

    const [messages, setMessages] = useState({ messages: [], alert: '' })
    const history = useHistory()
    const params = useParams()
    const formik = useFormik({
        initialValues: { email: '', password: '' },
        validationSchema: Yup.object(Validation(Yup)),
        onSubmit: async () => {
                return handleUserLogin(
                    history, 
                    {...formik.values, company: data?.id}, 
                    setMessages
                )
        }
    })

    return <Component formik={formik} messages={messages} data={data} params={params}/>
}

export default Page