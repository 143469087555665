import { CFormRange } from '@coreui/react'
import { models } from 'powerbi-client'
import { useContext, useEffect, useState } from 'react'
import { useBeforeunload } from 'react-beforeunload'
import { AiOutlineMinus, AiOutlinePlus } from 'react-icons/ai'
import { MdZoomOutMap } from 'react-icons/md'
import { useHistory, useParams } from 'react-router-dom'
import { useDebouncedCallback } from 'use-debounce'

import { Context } from 'contexts/context'

import { handleReport } from 'services/api/reports'

import {
  handlePowerBiReportAad,
  handlePowerBiReportEmbed,
} from 'services/api/powerbi'
import {
  handleCreateUserActionReport,
  handleUpdateUserActionReport,
} from 'services/api/usersActionsReports'

import { PowerBIEmbed } from 'powerbi-client-react'

import './style.css'
import useWindowSize from 'hooks/useWindowSize'

const Page = () => {
  const windowsSize = useWindowSize()

  const [data, setData] = useState({})
  const [userActionReportId, setUserActionReportId] = useState()

  const [isLoading, setIsLoading] = useState(true)
  const [hasMobile, setHasMobile] = useState(false)
  const [tent, setTent] = useState(false)

  const [isFitToPage, setIsFitToPage] = useState(true)
  const [rangeValue, setRangeValue] = useState(50)

  const debouncedRangeValue = useDebouncedCallback(async value => {
    setRangeValue(Number(value))
    await report.setZoom(value * 0.039 + 0.1)
  }, 500)

  const params = useParams()
  const history = useHistory()

  const { user, report, setReport, isLoaded, setIsLoaded } = useContext(Context)

  const { group_id, report_id, dataset_id } = params

  const getData = async () => {
    if (user.roles === 'admin') {
      const reportData = await handlePowerBiReportAad({ report_id, group_id })

      setData({
        reportId: reportData.reportId,
        embedUrl: reportData.embedUrl,
        token: reportData.accessToken,
        type: models.TokenType.Aad,
      })

      return reportData.accessToken
    }

    const roles = await handleReport(report_id, user.id)

    if (roles.roles) {
      const reportData = await handlePowerBiReportEmbed({
        group_id,
        report_id,
        dataset: dataset_id,
        username: user.email,
        roles: roles.roles,
      })

      handleUserAction(roles.report_card_id)

      setData({
        reportId: reportData.reportId,
        embedUrl: reportData.embedUrl,
        token: reportData.embedToken,
        page_navigation: roles.page_navigation,
        enable_filters: roles.enable_filters,
        type: models.TokenType.Embed,
        expiration: reportData.embedToken.expiration,
      })

      return reportData.embedToken
    }

    const reportData = await handlePowerBiReportAad({ report_id, group_id })

    handleUserAction(roles.report_card_id)

    setData({
      reportId: reportData.reportId,
      embedUrl: reportData.embedUrl,
      token: reportData.accessToken,
      page_navigation: roles.page_navigation,
      enable_filters: roles.enable_filters,
      type: models.TokenType.Aad,
    })

    return reportData.embedToken
  }

  useEffect(() => {
    if (user) getData()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user])

  const handleUserAction = async report_card_id => {
    const userActionReportResponse = await handleCreateUserActionReport({
      report_id: report_card_id,
    })

    setUserActionReportId(userActionReportResponse)
  }

  useBeforeunload(() => {
    if (user.roles !== 'admin') {
      handleUpdateUserActionReport(userActionReportId)
    }
  })

  const unblock = history.block(() => {
    if (user.roles !== 'admin') {
      handleUpdateUserActionReport(userActionReportId)
    }

    setReport(null)
    setIsLoaded(false)
    unblock()
  })

  const embedConfig = {
    type: 'report',
    id: data.reportId,
    embedUrl: data.embedUrl,
    eventHooks: data.type === models.TokenType.Aad && {
      accessTokenProvider: async () => await getData(),
    },
    accessToken: data.token,
    tokenType: data.type || models.TokenType.Aad,
    settings: {
      layoutType: models.LayoutType.Custom,
      customLayout: {
        displayOption:
          models.DisplayOption[`${isFitToPage ? 'FitToPage' : 'FitToWidth'}`],
      },
      panes: {
        filters: {
          visible: data.enable_filters ? data.enable_filters : false,
        },
        pageNavigation: {
          visible: data.page_navigation ? data.page_navigation : false,
        },
      },
    },
  }

  useEffect(() => {
    async function checkIfPageHasMobile() {
      try {
        const pages = await report.getPages()
        const pageActive = pages.find(page => page.isActive)

        if (pageActive) {
          const pageHasLayoutMobile = await pageActive.hasLayout(
            models.LayoutType.MobilePortrait,
          )

          if (pageHasLayoutMobile) {
            report.updateSettings({
              layout: models.LayoutType.MobilePortrait,
            })
            setHasMobile(true)
          } else {
            setHasMobile(false)
          }
        } else {
          setHasMobile(false)
        }
      } catch (err) {}
    }
    if (isLoaded) {
      if (windowsSize.width <= 700) {
        checkIfPageHasMobile()
      } else {
        setHasMobile(false)
      }
      setIsLoading(false)
    }
  }, [windowsSize, isLoaded, report])

  const handleOnChange = event => {
    debouncedRangeValue(event.target.value)
  }

  const handleFit = () => {
    setIsFitToPage(!isFitToPage)
  }

  const handlePlusZoom = async () => {
    if (rangeValue < 100) {
      const newRangeValue = rangeValue + 5 > 100 ? 100 : rangeValue + 5
      setRangeValue(newRangeValue)

      await report.setZoom(newRangeValue * 0.039 + 0.1)
    }
  }

  const handleMinusZoom = async () => {
    if (rangeValue > 0) {
      const newRangeValue = rangeValue - 5 < 0 ? 0 : rangeValue - 5
      setRangeValue(newRangeValue)

      await report.setZoom(newRangeValue * 0.039 + 0.1)
    }
  }

  useEffect(() => {
    if (isLoaded);
    ;(async () => {
      try {
        report
          ?.getZoom()
          .then(response =>
            setRangeValue(Number(((response - 0.1) / 0.039).toFixed(0))),
          )
      } catch (error) {
        console.log(error)
      }
    })()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isFitToPage, tent])

  const handleMouseMove = useDebouncedCallback(async value => {
    setTent(!tent)
  }, 500)

  return (
    <>
      {isLoading && (
        <div id="overlay">
          <img
            id="spinner"
            alt="Alternate Text"
            src={
              user.company
                ? `${process.env.REACT_APP_URL_API}/${user.company.logo_url}`
                : '/LOGO GK.png'
            }
          />
        </div>
      )}
      <div
        {...(isLoaded && {
          onMouseMove: ev => handleMouseMove(ev),
          onTouchMove: ev => handleMouseMove(ev),
        })}
        style={{
          visibility: isLoading || hasMobile ? 'hidden' : 'visible',
          position: isLoading || hasMobile ? 'absolute' : 'relative',
        }}
      >
        <PowerBIEmbed
          eventHandlers={
            new Map([
              ['rendered', () => setIsLoaded(true)],
              ['error', event => console.warn(event.detail)],
            ])
          }
          getEmbeddedComponent={embedObject => setReport(embedObject)}
          embedConfig={embedConfig}
          cssClassName={'report'}
        />
      </div>

      {windowsSize.width <= 700 && (
        <div
          style={{
            visibility: !hasMobile ? 'hidden' : 'visible',
            position: !hasMobile ? 'absolute' : 'relative',
          }}
        >
          <PowerBIEmbed
            eventHandlers={
              new Map([
                ['rendered', () => setIsLoaded(true)],
                ['error', event => console.warn(event.detail)],
              ])
            }
            getEmbeddedComponent={embedObject => setReport(embedObject)}
            embedConfig={{
              ...embedConfig,
              settings: {
                ...embedConfig.settings,
                layoutType: models.LayoutType.MobilePortrait,
              },
            }}
            cssClassName={'reportMobile'}
          />
        </div>
      )}

      {isLoaded && (
        <div id="reportRangeContainer">
          <button onClick={handleMinusZoom} className="zoomReportButton">
            <AiOutlineMinus />
          </button>

          <CFormRange
            steps={1}
            value={rangeValue}
            onChange={handleOnChange}
            id="reportRange"
          />

          <button onClick={handlePlusZoom} className="zoomReportButton">
            <AiOutlinePlus />
          </button>

          <span id="reportRangeValue">{rangeValue}%</span>

          <button className="zoomReportButton" onClick={handleFit}>
            <MdZoomOutMap />
          </button>
        </div>
      )}
    </>
  )
}

export default Page
