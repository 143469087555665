import { useEffect, useContext, useState } from 'react'
import { Route, Redirect, useHistory } from 'react-router-dom'

import { Context } from 'contexts/context'

import { getUser, userAuthenticated, logout } from 'services/auth'

const Component = ({ component: Component, redirect, ...rest }) => {
  const [error, setError] = useState(false)

    const { user, setUser } = useContext(Context)

    const history = useHistory()

    useEffect(() => {
        (async () => {
          try {
            const data = await getUser()

            localStorage.setItem('company', JSON.stringify(data.company))
    
            setUser(data)
          } catch(error) {
            if (error.response?.status === 401) {
              const company = JSON.parse(localStorage.getItem("company"))

              return logout(history, company?.slug)
            }

            setError(true)
          }
        })()
      // eslint-disable-next-line react-hooks/exhaustive-deps
      }, [])

    return (
      <>
        {(user || error) && (
          <Route {...rest} render={props => userAuthenticated() ? <Component error={error} {...props} /> : <Redirect to={JSON.parse(localStorage.getItem("company"))?.slug || "/login"} />}/>  
        )}

      </>
    )
}

export default Component