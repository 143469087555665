import api from './api'
import { setErrors } from 'utils'

export const handleCategories = async () => (await api.post(`/categories/list-by-firm`)).data

export const handleCategorieshow = async id => (await api.get(`/categories/${id}`)).data

export const handleCategoriesByFirm = async () =>
  (await api.post(`/categories/list-by-firm`)).data

export const handleCategoriesByUser = async () =>
  (await api.post(`/categories/list-by-user`)).data

  export const handleReportsByUserAndCategory = async (id) =>
  (await api.post(`/categories/list-by-user/reports/${id}`)).data

export const handleCategoriesStore = async (history, data, setMessages) => {
  const values = {
    "name": data.name
  }

  try {
    const response = await api.post(`/categories/store`, values)
    return history.push(`/categorias/${response.data.id}/upload`)
  } catch (error) {
    setMessages({ messages: setErrors(error), alert: 'alert-orange' })
  }
}

export const handleCategory = async (history, data, cardId, setMessages) => {
  const values = {
    "name": data.name
  }
  try {
    await api.put(`/categories/${cardId}/update`, values)
    return history.push(`/categorias`)
  } catch (error) {
    setMessages({ messages: setErrors(error), alert: 'alert-orange' })
  }
}

export const handleCategoryDelete = async (id, history) => {
  await api.delete(`/categories/${id}`)
  return history.push(`/categorias`)
}

export const handleCategoryUpdateFile = async (data, id) => {
  const values = new FormData()
  values.append('file', data)
  try {
    await api.put(`/categories/${id}/upload`, values)
    return
  } catch (error) {
    return error
  }
}