import styled from 'styled-components'

export const OverlayDiv = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  overflow: hidden;
  right: 0;
  background-color: #fff;
  opacity: 0.7;
  display: flex;
  align-items: center;
  justify-content: center;
`
