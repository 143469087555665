import TitleBar from 'components/TitleBar'
import Content from 'components/Content'
import Card from 'components/Card'
import CardTitle from 'components/CardTitle'
import Input from 'components/Form/LabelInput'
import BtnBlue from 'components/Button/BtnBlue'
import BtnOrange from 'components/Button/BtnOrange'
import BtnRed from 'components/Button/BtnRed'
import BtnBox from 'components/Button/BtnBox'
import BtnWhite from 'components/Button/BtnWhite'
import Messages from 'components/Messages'
import Spinner from 'components/Spinner'
import Modal from 'components/Modal'
import Select from 'components/Form/LabelSelect'
import Checkbox from 'components/Form/LabelCheckbox'
import { phoneMask, moneyMask } from '../../utils/index'
import { useEffect, useState } from 'react'
import CardBody from 'components/CardBody'
import { handleGeneratePassword } from 'services/api/users'
import { handleStatusByFirm } from 'services/api/status'
import { handleOriginsByFirm } from 'services/api/origins'
import { handleCompanies } from 'services/api/empresas'

const Component = props => {
  const [data, setData] = useState([])
  const [originData, setOriginData] = useState([])

  const [companiesData, setCompaniesData] = useState([])

  useEffect(() => {
    ;(async () => {
      setCompaniesData(await handleCompanies())
    })()
  }, [])

  useEffect(() => {
    ;(async () => {
      setData(await handleStatusByFirm())
    })()
  }, [])

  useEffect(() => {
    ;(async () => {
      setOriginData(await handleOriginsByFirm())
    })()
  }, [])

  return (
    <>
      <TitleBar label="Usuários" currentPage="" />
      <Content>
        <Card>
          <CardTitle title="Editar">
            <BtnOrange onClick={() => props.setVisibleModal(true)}>
              Excluir
            </BtnOrange>
          </CardTitle>
          <Content>
            <CardBody>
              <form onSubmit={props.formik.handleSubmit}>
                <Messages
                  formMessages={props.messages.messages}
                  alert={props.messages.alert}
                />
                <Input name="name" label="Nome" formik={props.formik} />
                <Input name="email" label="Email" formik={props.formik} />
                <Input
                  name="contact"
                  label="Contato"
                  mask={phoneMask}
                  formik={props.formik}
                />
                <Checkbox
                  name="text_message"
                  label="Enviar mensagem de texto?"
                  formik={props.formik}
                  checked={data.text_message}
                  onChange={e => {
                    props.formik.setFieldValue('text_message', e.target.checked)
                  }}
                />
                <Checkbox
                  name="voice_message"
                  label="Enviar mensagem de voz?"
                  formik={props.formik}
                  checked={data.voice_message}
                  onChange={e => {
                    props.formik.setFieldValue(
                      'voice_message',
                      e.target.checked,
                    )
                  }}
                />
                <Input
                  name="value"
                  label="Valor"
                  mask={moneyMask}
                  formik={props.formik}
                />
                <Select name="roles" label="Permissão" formik={props.formik}>
                  <option value="">Selecione...</option>
                  <option value="admin">Administrador</option>
                  <option value="user">Usuário</option>
                </Select>
                <Select name="status_id" label="Status" formik={props.formik}>
                  <option value="">Selecione...</option>
                  {data.map(item => (
                    <option key={item.id} value={item.id}>
                      {item.name}
                    </option>
                  ))}
                </Select>
                <Select name="origin_id" label="Origem" formik={props.formik}>
                  <option value="">Selecione...</option>
                  {originData.map(item => (
                    <option key={item.id} value={item.id}>
                      {item.name}
                    </option>
                  ))}
                </Select>
                <Select name="company_id" label="Empresa" formik={props.formik}>
                  <option value="">Selecione...</option>
                  {companiesData.map(item => (
                    <option key={item.id} value={item.id}>
                      {item.name}
                    </option>
                  ))}
                </Select>
                <BtnBox>
                  <BtnBlue type="submit" disabled={props.formik.isSubmitting}>
                    {props.formik.isSubmitting ? <Spinner /> : 'Enviar'}
                  </BtnBlue>

                  <span
                    onClick={async () => {
                      await handleGeneratePassword(props.id)
                      props.history.push(`/usuarios`)
                    }}
                    style={{ cursor: 'pointer', fontWeight: 'bold' }}
                  >
                    Gerar senha
                  </span>
                </BtnBox>
              </form>
            </CardBody>
          </Content>
        </Card>
      </Content>
      <Modal
        visibleModal={props.visibleModal}
        setVisibleModal={props.setVisibleModal}
      >
        <Card>
          <Content>
            <Content>
              <CardBody>
                <p>Deseja excluir esse item?</p>
                <BtnBox>
                  <BtnRed onClick={props.handleDelete}>Excluir</BtnRed>
                  <BtnWhite onClick={() => props.setVisibleModal(false)}>
                    Cancelar
                  </BtnWhite>
                </BtnBox>
              </CardBody>
            </Content>
          </Content>
        </Card>
      </Modal>
    </>
  )
}

export default Component
