import { useEffect, useState, useContext } from 'react'
import { useParams } from 'react-router-dom'
import { FaChartArea } from "react-icons/fa"
import { useFormik } from 'formik'

import { handlePowerBiReports } from 'services/api/powerbi'
import { handleReportsByUserWithCard } from 'services/api/reports'

import { Context } from 'contexts/context'

import TitleBar from 'components/TitleBar'
import Content from 'components/Content'
import Card from 'components/Card'
import CardTitle from 'components/CardTitle'
import Table from 'components/Table'
import Input from 'components/Form/LabelInput'
import SmLinkBlue from 'components/Button/SmLinkBlue'
import Container from 'components/Container'
import ReportCard from 'components/ReportCard'

const Page = () => {
  const [data, setData] = useState([])
  const [list, setList] = useState([])

  const formik = useFormik({
    initialValues: {
      search: ''
    }
  })

  const { user } = useContext(Context)

  const params = useParams()

  useEffect(() => {
    (async () => {
      try {
        if (user.roles === 'admin') {
          setData(await handlePowerBiReports(params.group_id))
        } else {
          setData(await handleReportsByUserWithCard())
        }
      } catch (error) { }
    })()
  }, [params.group_id, user.roles])

  useEffect(() => {
    setList(data.filter(item => item[`${user.roles === 'admin' ? "name" : "card_name"}`].toUpperCase().includes(formik.values.search.toUpperCase())))
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formik.values.search, data])

  return (
    <>
      <TitleBar label="Relatórios" currentPage="" />

      <Content>
        <Card>
          <CardTitle title="Meus Relatórios" />

          <div style={{ padding: "15px 15px 0 15px" }}>
            <Input
              placeholder="Buscar..."
              name="search"
              label=""
              formik={formik}
            />
          </div>

          {user.roles === 'admin' ?
            <Table>
              <thead>
                <tr>
                  <th>Nome</th>
                  <th width="50">Ações</th>
                </tr>
              </thead>

              <tbody>
                {list.map(item =>
                  <tr key={item.id}>
                    <td>{item.name}</td>

                    <td>
                      <SmLinkBlue to={`/grupos/${params.group_id}/relatorios/${item.id}/datasets/${item.datasetId}`}>
                        <FaChartArea />
                      </SmLinkBlue>
                    </td>
                  </tr>
                )}
              </tbody>
            </Table> :
            <Container>
              {list.map(item => <ReportCard key={item.id} name={item.card_name} fileUrl={item.file_url} linkTo={`/grupos/${item.group_id}/relatorios/${item.report_id}/datasets/${item.dataset_id}`} />)}
            </Container>
          }
        </Card>
      </Content>
    </>
  )
}

export default Page