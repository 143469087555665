import {
    format as formatFns,
    parseISO as parseISOFns,
    addDays as addDaysFns
} from "date-fns";

export const setErrors = error => error.response.data.errors.message

export const cepMask = value =>
    value.replace(/\D+/g, '')
        .replace(/(\d{5})(\d)/, '$1-$2')
        .replace(/(-\d{3})\d+?$/, '$1')

export const phoneMask = value =>
    value.replace(/\D+/g, '')
        .replace(/(\d{2})(\d)/, '($1) $2')
        .replace(/(\d{4})(\d)/, '$1-$2')
        .replace(/(\d{4})-(\d)(\d{4})/, '$1$2-$3')
        .replace(/(-\d{4})\d+?$/, '$1')

export const cnpjMask = value =>
    value.replace(/\D/g, '')
        .replace(/(\d{2})(\d)/, '$1.$2')
        .replace(/(\d{3})(\d)/, '$1.$2')
        .replace(/(\d{3})(\d)/, '$1/$2')
        .replace(/(\d{4})(\d)/, '$1-$2')
        .replace(/(-\d{2})\d+?$/, '$1')

export const cnpjUnmask = value =>
    value.replace(/\D+/g, '')
        .replace(/([0-9])/, '$1')

export const cpfMask = value =>
    value.replace(/\D/g, '')
        .replace(/(\d{3})(\d)/, '$1.$2')
        .replace(/(\d{3})(\d)/, '$1.$2')
        .replace(/(\d{3})(\d{1,2})/, '$1-$2')
        .replace(/(-\d{2})\d+?$/, '$1')

export const numberMask = value =>
    value.replace(/\D/g, '')
        .replace(/(\d{3})(\d)/, '$1.$2')
        .replace(/(\d{3})(\d)/, '$1.$2')
        .replace(/(\d{3})(\d)/, '$1.$2')
        .replace(/(\d{3})(\d)/, '$1.$2')

export const slugMask = value =>
        value?.trim()?.toLowerCase()?.replace(/ /g, '-')?.normalize("NFD")?.replace(/[\u0300-\u036f]/g, "")

export const moneyMask = value => {
    value = value.replace('.', '').replace(',', '').replace(/\D/g, '')

    const options = { minimumFractionDigits: 2 }
    const result = new Intl.NumberFormat('pt-BR', options).format(
      parseFloat(value) / 100
    )
  
    return 'R$ ' + result
};

export const removeMoneyMask = formattedValue => {
    const numericValue = formattedValue.replace(/\D/g, '');

    const actualValue = parseFloat(numericValue) / 100;

    return actualValue;
};

export const toMoney = value =>
    Number(value).toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })

export const formatDate = (date) => {
    return formatFns(
        parseISOFns(date),
        "dd/MM/yyyy 'às' HH:mm:ss"
    );
}

export const addDays = (date, amount) => {
    return addDaysFns(date, amount)
}

export const downloadFile = (blob, filename) => {
    const url = window.URL.createObjectURL(blob)
    const link = document.createElement("a")

    link.href = url

    link.setAttribute(
        'download',
        filename,
    )

    document.body.appendChild(link)

    link.click()
    link.parentNode.removeChild(link)
}