import { Link } from 'react-router-dom'
import { Breadcrumb, TitleBar, Background, TitleContainer } from './styled'
import { BtnWhite } from 'components/Button/styled'
import { FaArrowLeft } from 'react-icons/fa'
const Component = props => {
  return (
    <Background>
      <TitleBar>
        <div>
          <TitleContainer>
            {props.showBack && (
              <BtnWhite
                style={{ padding: '5px' }}
                onClick={() => props.history.goBack()}
              >
                <FaArrowLeft />
              </BtnWhite>
            )}
            <h1>{props.label}</h1>
          </TitleContainer>
          <Breadcrumb>
            {props.breadcrumb &&
              props.breadcrumb.map((item, index) => (
                <li key={index}>
                  <Link to={item.path}>{item.label}</Link>/
                </li>
              ))}
            <li>{props.currentPage}</li>
          </Breadcrumb>
        </div>
      </TitleBar>
    </Background>
  )
}

export default Component
