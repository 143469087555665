import ptBR from 'date-fns/locale/pt-BR'

import { ReactDatePicker } from "./styled"

import { Label } from '../styled'

export default function DatePicker ({ label, name, coolFormat, showTime, minDate, maxDate, formik  }) {
        const dateFormat = coolFormat ? `EEEE ${showTime ? " 'às' HH:mm" : ""}` : `dd/MM/yyyy${showTime ? " HH:mm" : ""}`
    
    return (
        <div style={{ marginBottom: "16px", width: "100%" }}>
            <Label>
                <label htmlFor={name}>{label}</label>

                <div>
                    {formik.touched[name] && formik.errors[name] ? formik.errors[name] : null}
                </div>
            </Label>

            <ReactDatePicker 
                name={name} 
                locale={ptBR}
                showTimeSelect={showTime}
                maxDate={minDate}
                minDate={maxDate}
                selected={formik.values[name]} 
                timeFormat="HH:mm"
                dateFormat={dateFormat}
                onChange={date => formik.setFieldValue(name, date)}
                onKeyDown={event => { event.preventDefault() }}
            />
        </div>
    )
}