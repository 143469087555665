import PersoanlizedTemplateLogin from 'components/PersoanlizedTemplateLogin'
import Private from 'components/Routes/Private'
import Public from 'components/Routes/Public'
import Template from 'components/Template'
import GlobalStyle from 'main/GlobalStyle'
import { BrowserRouter, Route, Switch } from 'react-router-dom'

import ContextProvider from 'contexts/context'

import ForgotPassword from 'pages/ForgotPassword'
import Login from 'pages/Login'
import NoMatch from 'pages/NoMatch'
import Profile from 'pages/Profile'
import RecoverPassword from 'pages/RecoverPassword'

import Index from 'pages/Index'

import Groups from 'pages/Groups'
import Home from 'pages/Home'
import HomeReports from 'pages/HomeReports'
import Page from 'pages/Page'
import Report from 'pages/Report'
import Reports from 'pages/Reports'

import Cards from 'pages/Cards'
import CardsStore from 'pages/CardsStore'
import CardsUpdate from 'pages/CardsUpdate'
import CardsUpdateImage from 'pages/CardsUpdateImage'

import Categories from 'pages/Categories'
import CategoriesStore from 'pages/CategoriesStore'
import CategoriesUpdate from 'pages/CategoriesUpdate'
import CategoriesUpdateImage from 'pages/CategoriesUpdateImage'

import ReportsUserStore from 'pages/ReportsUserStore'
import ReportsUserUpdate from 'pages/ReportsUserUpdate'
import UserAccessControl from 'pages/UserAccessControl'
import ReportsUser from 'pages/UserReports'
import Users from 'pages/Users'
import UsersStore from 'pages/UsersStore'
import UsersUpdate from 'pages/UsersUpdate'

import NewUserAccessControl from 'pages/UserAccessControl/New'
import NewUserReportsCollection from 'pages/UserReports/Collections/New'

import ReportsCollection from 'pages/ReportsCollection'
import NewReportsCollection from 'pages/ReportsCollection/New'
import ReportsCollectionItems from 'pages/ReportsCollection/Reports'
import UpdateReportsCollectionItems from 'pages/ReportsCollection/Reports/Update'
import UpdateReportsCollection from 'pages/ReportsCollection/Update'

import Companies from 'pages/Companies'
import NewCompany from 'pages/Companies/New'
import UpdateCompany from 'pages/Companies/Update'

import OriginsStore from 'pages/OriginsStore'
import OriginsUpdate from 'pages/OriginsUpdate'
import StatusStore from 'pages/StatusStore'
import StatusUpdate from 'pages/StatusUpdate'

import Analytics from 'pages/Analytics'

import Capacities from 'pages/Capacities'
import CapacitiesUpdate from 'pages/CapacitiesUpdate'
import CapacitiesOptimizeCosts from 'pages/CapacitiesOptimizeCosts'

import Error from 'pages/Error'

const App = () => {
  return (
    <>
      <GlobalStyle />
      <ContextProvider>
        <BrowserRouter>
          <Switch>
            <Private
              path="/profile"
              component={Template(Profile)}
              redirect="/login"
            />

            <Private
              path="/grupos/:group_id/relatorios/:report_id/datasets/:dataset_id"
              component={Template(Report)}
              redirect="/login"
            />
            <Private
              path="/grupos/:group_id/relatorios"
              component={Template(Reports)}
              redirect="/login"
            />
            <Private
              path="/grupos"
              component={Template(Groups)}
              redirect="/login"
            />

            <Private
              path="/categorias/:id/upload"
              component={Template(CategoriesUpdateImage)}
              redirect="/login"
            />
            <Private
              path="/categorias/:id/editar"
              component={Template(CategoriesUpdate)}
              redirect="/login"
            />
            <Private
              path="/categorias/cadastrar"
              component={Template(CategoriesStore)}
              redirect="/login"
            />
            <Private
              path="/categorias"
              component={Template(Categories)}
              redirect="/login"
            />

            <Private
              path="/cards/:id/upload"
              component={Template(CardsUpdateImage)}
              redirect="/login"
            />
            <Private
              path="/cards/:id/editar"
              component={Template(CardsUpdate)}
              redirect="/login"
            />
            <Private
              path="/cards/cadastrar"
              component={Template(CardsStore)}
              redirect="/login"
            />
            <Private
              path="/cards"
              component={Template(Cards)}
              redirect="/login"
            />

            <Private
              path="/home/pagina/:id"
              component={Template(Page)}
              redirect="/login"
            />
            <Private
              path="/home/:id"
              component={Template(HomeReports)}
              redirect="/login"
            />
            <Private
              path="/home"
              component={Template(Home)}
              redirect="/login"
            />

            <Private
              path="/relatorios"
              component={Template(Reports)}
              redirect="/login"
            />

            <Private
              path="/usuarios/:id/relatorios/:report_id/editar"
              component={Template(ReportsUserUpdate)}
              redirect="/login"
            />
            <Private
              path="/usuarios/:id/relatorios/cadastrar"
              component={Template(ReportsUserStore)}
              redirect="/login"
            />
            <Private
              path="/usuarios/:id/colecoes/cadastrar"
              component={Template(NewUserReportsCollection)}
              redirect="/login"
            />
            <Private
              path="/usuarios/:id/acessos/cadastrar"
              component={Template(NewUserAccessControl)}
              redirect="/login"
            />
            <Private
              path="/usuarios/:id/relatorios"
              component={Template(ReportsUser)}
              redirect="/login"
            />
            <Private
              path="/usuarios/:id/acessos"
              component={Template(UserAccessControl)}
              redirect="/login"
            />
            <Private
              path="/usuarios/:id/editar"
              component={Template(UsersUpdate)}
              redirect="/login"
            />
            <Private
              path="/usuarios/cadastrar/status/:id/editar"
              component={Template(StatusUpdate)}
              redirect="/login"
            />
            <Private
              path="/usuarios/cadastrar/status/cadastrar"
              component={Template(StatusStore)}
              redirect="/login"
            />
            <Private
              path="/usuarios/cadastrar/origens/:id/editar"
              component={Template(OriginsUpdate)}
              redirect="/login"
            />
            <Private
              path="/usuarios/cadastrar/origens/cadastrar"
              component={Template(OriginsStore)}
              redirect="/login"
            />
            <Private
              path="/usuarios/cadastrar"
              component={Template(UsersStore)}
              redirect="/login"
            />
            <Private
              path="/usuarios"
              component={Template(Users)}
              redirect="/login"
            />

            <Private
              path="/colecoes/:collectionId/relatorios/:id/editar"
              component={Template(UpdateReportsCollectionItems)}
              redirect="/login"
            />
            <Private
              path="/colecoes/:id/relatorios"
              component={Template(ReportsCollectionItems)}
              redirect="/login"
            />
            <Private
              path="/colecoes/:id/editar"
              component={Template(UpdateReportsCollection)}
              redirect="/login"
            />
            <Private
              path="/colecoes/cadastrar"
              component={Template(NewReportsCollection)}
              redirect="/login"
            />
            <Private
              path="/colecoes"
              component={Template(ReportsCollection)}
              redirect="/login"
            />

            <Private
              path="/empresas/:id/editar"
              component={Template(UpdateCompany)}
              redirect="/login"
            />
            <Private
              path="/empresas/cadastrar"
              component={Template(NewCompany)}
              redirect="/login"
            />
            <Private
              path="/empresas"
              component={Template(Companies)}
              redirect="/login"
            />

            <Private
              path="/capacidades/:id/otimizar-custos"
              component={Template(CapacitiesOptimizeCosts)}
              redirect="/login"
            />

            <Private
              path="/capacidades/:id/editar"
              component={Template(CapacitiesUpdate)}
              redirect="/login"
            />

            <Private
              path="/capacidades"
              component={Template(Capacities)}
              redirect="/login"
            />

            <Private
              path="/analytics"
              component={Template(Analytics)}
              redirect="/login"
            />
            <Private
              path="/error"
              component={Template(Error)}
              redirect="/login"
            />

            <Public
              path="/:slug/recuperar-senha/:token"
              component={PersoanlizedTemplateLogin(RecoverPassword)}
              redirect="/dashboard"
            />
            <Public
              path="/:slug/esqueci-senha"
              component={PersoanlizedTemplateLogin(ForgotPassword)}
              redirect="/"
            />
            <Public
              path="/:slug"
              component={PersoanlizedTemplateLogin(Login)}
              redirect="/"
            />

            <Private path="/" component={Index} redirect="/login" />

            <Route path="*">
              <NoMatch />
            </Route>
          </Switch>
        </BrowserRouter>
      </ContextProvider>
    </>
  )
}

export default App
