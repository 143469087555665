import { useEffect, useState } from 'react'
import { useHistory, useParams } from 'react-router-dom'
import { useFormik } from 'formik'
import { RiAddLine } from 'react-icons/ri'

import * as Yup from 'yup'

import TitleBar from 'components/TitleBar'
import Content from 'components/Content'
import Card from 'components/Card'
import CardTitle from 'components/CardTitle'
import Input from 'components/Form/LabelInput'
import Select from 'components/Form/Select'
import IconButton from 'components/Button/IconButton'
import BtnBlue from 'components/Button/BtnBlue'
import Messages from 'components/Messages'
import Spinner from 'components/Spinner'
import BtnOrange from 'components/Button/BtnOrange'
import CardBody from 'components/CardBody'
import BtnRed from 'components/Button/BtnRed'
import BtnBox from 'components/Button/BtnBox'
import BtnWhite from 'components/Button/BtnWhite'
import Modal from 'components/Modal'

import api from 'services/api/api'

import { handleCards } from 'services/api/reportCards'
import { handleUsersByRoles } from 'services/api/users'

import { setErrors } from 'utils'

const validation = yup => ({
  name: yup.string().required('Campo obrigatório'),
  reports: yup.array().min(1, 'Campo obrigatório'),
  users: yup.array().min(1, 'Campo obrigatório'),
})

const NewReportCollection = () => {
  const [cards, setCards] = useState([])
  const [users, setUsers] = useState([])

  const [reportsCollection, setReportsCollection] = useState({})

  const [visibleModal, setVisibleModal] = useState(false)
  const [messages, setMessages] = useState({ messages: [], alert: '' })

  const history = useHistory()
  const params = useParams()

  const formik = useFormik({
    initialValues: {
      name: reportsCollection.name,
      reports: reportsCollection.reports?.map(report => ({
        value: report.id,
        label: report.name,
      })),
      users: reportsCollection.users?.map(user => ({
        value: user.id,
        label: user.name,
      })),
    },
    enableReinitialize: true,
    validationSchema: Yup.object(validation(Yup)),
    onSubmit: async () => {
      const reports = formik.values.reports.map(report => report.value)
      const users = formik.values.users.map(user => user.value)

      try {
        await api.put(`/collections/${params.id}`, {
          ...formik.values,
          reports,
          users,
        })

        history.push('/colecoes')
      } catch (error) {
        setMessages({ messages: setErrors(error), alert: 'alert-orange' })
      }
    },
  })

  useEffect(() => {
    ;(async () => {
      const cardsData = await handleCards()
      const usersData = await handleUsersByRoles('user')

      setCards(cardsData)
      setUsers(usersData)
    })()
  }, [])

  useEffect(() => {
    ;(async () => {
      try {
        const { data } = await api.get(`/collections/${params.id}`)

        setReportsCollection(data)
      } catch (error) {}
    })()
  }, [params.id])

  const handleDelete = async () => {
    try {
      await api.delete(`/collections/${params.id}`)

      return history.push('/colecoes')
    } catch (error) {
      setMessages({ messages: setErrors(error), alert: 'alert-orange' })
    }
  }

  return (
    <>
      <TitleBar label="Grupos" currentPage="" />

      <Content>
        <Card>
          <CardTitle title="Editar">
            <BtnOrange onClick={() => setVisibleModal(true)}>Excluir</BtnOrange>
          </CardTitle>

          <Content>
            <CardBody>
              <form onSubmit={formik.handleSubmit}>
                <Messages
                  formMessages={messages.messages}
                  alert={messages.alert}
                />

                <Input name="name" label="Nome" formik={formik} />

                <Select
                  name="reports"
                  label="Relatórios"
                  isMulti={true}
                  moreItems={
                    <div>
                      <IconButton
                        Icon={RiAddLine}
                        color="#142030"
                        text="Selecionar todos"
                        onClick={() => {
                          formik.setFieldValue(
                            'reports',
                            cards.map(card => ({
                              value: card.id,
                              label: card.name,
                            })),
                          )
                        }}
                      />
                    </div>
                  }
                  options={cards.map(card => ({
                    value: card.id,
                    label: card.name,
                  }))}
                  formik={formik}
                />

                <Select
                  name="users"
                  label="Usuários"
                  moreItems={
                    <div>
                      <IconButton
                        Icon={RiAddLine}
                        color="#142030"
                        text="Selecionar todos"
                        onClick={() => {
                          formik.setFieldValue(
                            'users',
                            users.map(user => ({
                              value: user.id,
                              label: user.name,
                            })),
                          )
                        }}
                      />
                    </div>
                  }
                  isMulti={true}
                  options={users.map(user => ({
                    value: user.id,
                    label: user.name,
                  }))}
                  formik={formik}
                />

                {/* <Input name="roles" label="Roles" formik={formik} />

                {reportsCollection.id && <Checkbox name='page_navigation' label='Habilitar Abas' formik={formik} checked={reportsCollection.page_navigation} />} */}

                <BtnBox>
                  <BtnBlue type="submit" disabled={formik.isSubmitting}>
                    {formik.isSubmitting ? <Spinner /> : 'Enviar'}
                  </BtnBlue>
                </BtnBox>
              </form>
            </CardBody>
          </Content>
        </Card>
      </Content>

      <Modal visibleModal={visibleModal} setVisibleModal={setVisibleModal}>
        <Card>
          <Content>
            <Content>
              <CardBody>
                <p>Deseja excluir esta coleção de relatórios?</p>

                <BtnBox>
                  <BtnRed onClick={handleDelete}>Excluir</BtnRed>
                  <BtnWhite onClick={() => setVisibleModal(false)}>
                    Cancelar
                  </BtnWhite>
                </BtnBox>
              </CardBody>
            </Content>
          </Content>
        </Card>
      </Modal>
    </>
  )
}

export default NewReportCollection
