import TitleBar from 'components/TitleBar'
import Card from 'components/Card'
import CardTitle from 'components/CardTitle'
import Table from 'components/Table'
import Content from 'components/Content'
import LinkBlue from 'components/Button/LinkBlue'
import SmLinkBlue from 'components/Button/SmLinkBlue'
import Input from 'components/Form/LabelInput'
import Select from 'components/Form/LabelSelect'
import BtnBlue from 'components/Button/BtnBlue'
import Checkbox from 'components/Form/LabelCheckbox'
import Messages from 'components/Messages'
import Spinner from 'components/Spinner'
import { FaEdit } from 'react-icons/fa'
import Td from 'components/Td'
import CardBody from 'components/CardBody'
import { phoneMask, moneyMask } from '../../utils/index'
import { useEffect, useState } from 'react'
import BtnBox from 'components/Button/BtnBox'

import { handleStatusByFirm } from 'services/api/status'
import { handleOriginsByFirm } from 'services/api/origins'
import { handleCompanies } from 'services/api/empresas'

const Component = props => {
  const [data, setData] = useState([])
  const [list, setList] = useState([])

  const [originData, setOriginData] = useState([])
  const [originList, setOriginList] = useState([])

  const [companiesData, setCompaniesData] = useState([])

  useEffect(() => {
    ;(async () => {
      setCompaniesData(await handleCompanies())
    })()
  }, [])

  useEffect(() => {
    ;(async () => {
      setData(await handleStatusByFirm())
    })()
  }, [])

  useEffect(() => {
    setList(
      data.filter(item =>
        item.name
          .toUpperCase()
          .includes(props.formik.values.search.toUpperCase()),
      ),
    )
  }, [props.formik.values.search, data])

  useEffect(() => {
    ;(async () => {
      setOriginData(await handleOriginsByFirm())
    })()
  }, [])

  useEffect(() => {
    setOriginList(
      originData.filter(item =>
        item.name
          .toUpperCase()
          .includes(props.formik.values.searchOrigin.toUpperCase()),
      ),
    )
  }, [props.formik.values.searchOrigin, originData])

  return (
    <>
      <TitleBar label="Usuários" currentPage="" />
      <Content>
        <Card>
          <CardTitle title="Cadastrar"></CardTitle>
          <Content>
            <CardBody>
              <form onSubmit={props.formik.handleSubmit}>
                <Messages
                  formMessages={props.messages.messages}
                  alert={props.messages.alert}
                />
                <Input name="name" label="Nome" formik={props.formik} />
                <Input name="email" label="Email" formik={props.formik} />
                <Input
                  name="contact"
                  label="Contato"
                  mask={phoneMask}
                  formik={props.formik}
                />
                <Input
                  name="value"
                  label="Valor"
                  mask={moneyMask}
                  formik={props.formik}
                />
                <Select name="roles" label="Permissão" formik={props.formik}>
                  <option value="">Selecione...</option>
                  <option value="admin">Administrador</option>
                  <option value="user">Usuário</option>
                </Select>
                <Select name="status_id" label="Status" formik={props.formik}>
                  <option value="">Selecione...</option>
                  {data.map(item => (
                    <option key={item.id} value={item.id}>
                      {item.name}
                    </option>
                  ))}
                </Select>
                <Select name="origin_id" label="Origem" formik={props.formik}>
                  <option value="">Selecione...</option>
                  {originData.map(item => (
                    <option key={item.id} value={item.id}>
                      {item.name}
                    </option>
                  ))}
                </Select>
                <Select name="company_id" label="Empresa" formik={props.formik}>
                  <option value="">Selecione...</option>
                  {companiesData.map(item => (
                    <option key={item.id} value={item.id}>
                      {item.name}
                    </option>
                  ))}
                </Select>
                <Checkbox
                  name="send_mail"
                  label="Enviar E-mail"
                  formik={props.formik}
                  onChange={e => {
                    props.formik.setFieldValue(
                      'send_mail',
                      e.target.checked ? 1 : 0,
                    )
                  }}
                />
                <BtnBox>
                  <BtnBlue type="submit" disabled={props.formik.isSubmitting}>
                    {props.formik.isSubmitting ? <Spinner /> : 'Enviar'}
                  </BtnBlue>
                </BtnBox>
              </form>
            </CardBody>
          </Content>
        </Card>
      </Content>

      <Content>
        <Card>
          <CardTitle title="Origens">
            <LinkBlue to="/usuarios/cadastrar/origens/cadastrar">
              Cadastrar
            </LinkBlue>
          </CardTitle>

          <div style={{ padding: '15px 15px 0 15px' }}>
            <Input
              placeholder="Buscar..."
              name="searchOrigin"
              label=""
              formik={props.formik}
            />
          </div>

          <Table>
            <thead>
              <tr>
                <th width="60">#</th>
                <th>Nome</th>
                <th width="100">Ações</th>
              </tr>
            </thead>
            <tbody>
              {originList.map((item, index) => (
                <tr key={item.id}>
                  <td>{`#${item.id}`}</td>
                  <td>{item.name}</td>
                  <Td>
                    <SmLinkBlue
                      to={`/usuarios/cadastrar/origens/${item.id}/editar`}
                    >
                      <FaEdit />
                    </SmLinkBlue>
                  </Td>
                </tr>
              ))}
            </tbody>
          </Table>
        </Card>
      </Content>

      <Content>
        <Card>
          <CardTitle title="Status">
            <LinkBlue to="/usuarios/cadastrar/status/cadastrar">
              Cadastrar
            </LinkBlue>
          </CardTitle>

          <div style={{ padding: '15px 15px 0 15px' }}>
            <Input
              placeholder="Buscar..."
              name="search"
              label=""
              formik={props.formik}
            />
          </div>

          <Table>
            <thead>
              <tr>
                <th width="60">#</th>
                <th>Nome</th>
                <th width="100">Ações</th>
              </tr>
            </thead>
            <tbody>
              {list.map((item, index) => (
                <tr key={item.id}>
                  <td>{`#${item.id}`}</td>
                  <td>{item.name}</td>
                  <Td>
                    <SmLinkBlue
                      to={`/usuarios/cadastrar/status/${item.id}/editar`}
                    >
                      <FaEdit />
                    </SmLinkBlue>
                  </Td>
                </tr>
              ))}
            </tbody>
          </Table>
        </Card>
      </Content>
    </>
  )
}

export default Component
