import api from "./api/api";

import config from 'config'

export const userAuthenticated = () => {
    return localStorage.getItem(config.tokenKey) !== null
}

export const getUser = async () => {
    const { data } = await api.get(`/users`)

    return data
}

export const getToken = () => localStorage.getItem(config.tokenKey)
export const getUniqueID = () => localStorage.getItem(config.uniqueId)

export const login = (token, id) => {
    localStorage.setItem(config.tokenKey, token)
    localStorage.setItem(config.uniqueId, id)
}

export const logout = (history, slug) => {
    localStorage.removeItem(config.tokenKey)
    localStorage.removeItem(config.uniqueId)
    if (slug) {
        return history.push(`/${slug}`) 
    }

    return history.push('/login')
}