import { useFormik } from 'formik'
import { useEffect, useState } from 'react'
import { FaEdit } from "react-icons/fa"

import LinkBlue from 'components/Button/LinkBlue'
import SmLinkBlue from 'components/Button/SmLinkBlue'
import Card from 'components/Card'
import CardTitle from 'components/CardTitle'
import Content from 'components/Content'
import Input from 'components/Form/LabelInput'
import Table from 'components/Table'
import TitleBar from 'components/TitleBar'

import Td from 'components/Td'

import { handleCompanies } from 'services/api/empresas'

export default function ReportsCollection() {
    const [reportsCollections, setReportsCollections] = useState([])
    const [list, setList] = useState([])
    
    const formik = useFormik({
        initialValues: {
        search: ''
        }
    })

    useEffect(() => {
        (async () => {
            try {
                setReportsCollections(await handleCompanies())
            } catch(error) {

            }
          })()
    }, [])

    useEffect(() => {
        setList(reportsCollections.filter(item => item.name.toUpperCase().includes(formik.values.search.toUpperCase())))
    }, [formik.values.search, reportsCollections])

    return (
        <>
        <TitleBar label="Empresas" currentPage="" />

        <Content>
            <Card>
                <CardTitle title="Listagem">
                    <LinkBlue to="/empresas/cadastrar">Cadastrar</LinkBlue>
                </CardTitle>

                <div style={{ padding: "15px 15px 0 15px" }}>
                    <Input
                    placeholder="Buscar..."
                    name="search"
                    label=""
                    formik={formik}
                    />
                </div>

                <Table>
                    <thead>
                        <tr>
                            <th width="60">#</th>
                            <th>Nome</th>
                            <th width="100">Ações</th>
                        </tr>
                    </thead>
                    <tbody>
                        {list.map(reportsCollection =>
                            <tr key={reportsCollection.id}>
                                <td>{reportsCollection.id}</td>
                                <td>{reportsCollection.name}</td>

                                <Td>
                                    <SmLinkBlue to={`/empresas/${reportsCollection.id}/editar`}>
                                        <FaEdit />
                                    </SmLinkBlue>
                                </Td>
                            </tr>
                        )}
                    </tbody>
                </Table>
            </Card>
        </Content>
    </>
    )
}