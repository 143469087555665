import { createGlobalStyle } from 'styled-components'

import '@coreui/coreui/dist/css/coreui.min.css'
//00AF30
const GlobalStyle = createGlobalStyle`
    :root {
        --color-sidebar: #142030;
        --color-sidebar-hover: #fff;
        --color-background: #f8f8f8;
        --color-background-card: #ffffff;
        --color-background-title-bar: #f8f8f8;
        --color-background-footer: #f8f8f8;
        --color-background-input: #f2fafd;
        --color-background-report-card: #f2fafd;
        --color-font: #142030;
        --color-icons: #fff;
        --color-white: #142030;
        --color-grey: #d3cede;
        --color-blue: #0180c3;
        --color-green: #CACCDD;
        --color-orange: #4DB0D5;
        --color-red: #497AC2;
        --color-yellow: #4DB0D5;
        --color-fff: #fff;
        --color-000: #000;
        --color-red: #990000
    }
    * {
        box-sizing: border-box;
    }
    body {
        font-family: Arial, Helvetica, sans-serif;
        margin: 0;
		padding: 0;
        background-color: var(--color-background);
        color: var(--color-font);
        max-width: 100vw;
        overflow-x: hidden;
    }
    h1, h2, h3, h4, h5, h6, p {
        margin: 0;
    }
`
export default GlobalStyle
