import api from './api'
import { setErrors } from 'utils'

export const handleStatushow = async id => (await api.get(`/status/${id}`)).data

export const handleStatusByFirm = async () =>
  (await api.post(`/status/list-by-firm`)).data

export const handleStatusStore = async (history, data, setMessages) => {
  const values = {
    name: data.name,
  }

  try {
    await api.post(`/status/store`, values)
    return history.push(`/usuarios/cadastrar`)
  } catch (error) {
    setMessages({ messages: setErrors(error), alert: 'alert-orange' })
  }
}

export const handleStatusUpdate = async (
  history,
  data,
  cardId,
  setMessages,
) => {
  const values = {
    name: data.name,
  }
  try {
    await api.put(`/status/${cardId}/update`, values)
    return history.push(`/usuarios/cadastrar`)
  } catch (error) {
    setMessages({ messages: setErrors(error), alert: 'alert-orange' })
  }
}

export const handleStatusDelete = async (id, history) => {
  await api.delete(`/status/${id}`)
  return history.push(`/usuarios/cadastrar`)
}
