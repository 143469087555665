import BtnBlue from 'components/Button/BtnBlue'
import BtnBox from 'components/Button/BtnBox'
import Card from 'components/Card'
import CardBody from 'components/CardBody'
import CardTitle from 'components/CardTitle'
import Content from 'components/Content'
import Input from 'components/Form/LabelInput'
import Messages from 'components/Messages'
import Spinner from 'components/Spinner'
import TitleBar from 'components/TitleBar'
import { useFormik } from 'formik'
import { useState } from 'react'
import { useHistory } from 'react-router-dom'
import { handleCategoriesStore } from 'services/api/categories'
import * as Yup from 'yup'
import Validation from './validation'

const Page = () => {
  const [messages, setMessages] = useState({ messages: [], alert: '' })
  const history = useHistory()
  const formik = useFormik({
    initialValues: { name: '' },
    validationSchema: Yup.object(Validation(Yup)),
    onSubmit: () => handleCategoriesStore(history, formik.values, setMessages)
  })

  return (
    <>
      <TitleBar label="Categorias" currentPage="" />
      <Content>
        <Card>
          <CardTitle title="Cadastrar"></CardTitle>
          <Content>
            <CardBody>
              <form onSubmit={formik.handleSubmit}>
                <Messages formMessages={messages.messages} alert={messages.alert} />
                <Input name="name" label="Nome" formik={formik} />
                <BtnBox>
                  <BtnBlue type="submit" disabled={formik.isSubmitting}>
                    {formik.isSubmitting ? <Spinner /> : "Enviar"}
                  </BtnBlue>
                </BtnBox>
              </form>
            </CardBody>
          </Content>
        </Card>
      </Content>
    </>
  )
}

export default Page
