import axios from 'axios'
import config from 'config'

import { getToken, getUniqueID } from 'services/auth'

const api = axios.create({ baseURL: config.envApi })

api.interceptors.request.use(
    async config => {
        const token = getToken()
        const uniqueId = getUniqueID()

        if (token) config.headers.Authorization = `${token}`
        if (uniqueId) config.headers.uniqueId = `${uniqueId}`

        return config
    }
)

export default api
