const optimizeCostsValidation = yup => ({
  optimize_cost: yup.boolean(),
  idle_suspend_period: yup.number().when('optimize_cost', {
    is: true,
    then: yup
      .number()
      .moreThan(0, 'Selecione um valor para o período de inatividade'),
    otherwise: yup.number(),
  }),
})

// import Yup from 'yup'
const idleIntervalsValidation = yup => ({
  idle_intervals: yup.array(
    yup.object({
      id: yup.string().optional(),
      start_time: yup
        .string()
        .matches(
          /([01]?[0-9]|2[0-3]):[0-5][0-9]/,
          'Formato de horário inválido',
        )
        .required('O horário é obrigatório'),
      end_time: yup
        .string()
        .matches(
          /([01]?[0-9]|2[0-3]):[0-5][0-9]/,
          'Formato de horário inválido',
        )
        .required('O horário é obrigatório'),
    }),
  ),
  idle_intervals_to_remove: yup.array(yup.number()).optional(),
})

export { optimizeCostsValidation, idleIntervalsValidation }
