import { useEffect, useState } from 'react'
import { useFormik } from 'formik'

import TitleBar from 'components/TitleBar'
import Content from 'components/Content'
import Card from 'components/Card'
import CardTitle from 'components/CardTitle'
import Input from 'components/Form/LabelInput'
import Table from 'components/Table'
import SmLinkBlue from 'components/Button/SmLinkBlue'
import { FaChartBar } from "react-icons/fa"

const Component = props => {
    const [list, setList] = useState([])
    
    const formik = useFormik({
        initialValues: {
        search: ''
        }
    })

    useEffect(() => {
        setList(props.data.filter(item => item.name.toUpperCase().includes(formik.values.search.toUpperCase())))
    }, [formik.values.search, props.data])

    return (
        <>
            <TitleBar label="Relatórios" currentPage="" />
            <Content>
                <Card>
                    <CardTitle title="Grupos" />

                    <div style={{ padding: "15px 15px 0 15px" }}>
                        <Input
                        placeholder="Buscar..."
                        name="search"
                        label=""
                        formik={formik}
                        />
                    </div>

                    <Table>
                        <thead>
                            <tr>
                                <th>Nome</th>
                                <th width="50">Ações</th>
                            </tr>
                        </thead>
                        <tbody>
                            {list.map(item =>
                                <tr key={item.id}>
                                    <td>{item.name}</td>
                                    <td>
                                        <SmLinkBlue to={`/grupos/${item.id}/relatorios`}>
                                            <FaChartBar />
                                        </SmLinkBlue>
                                    </td>
                                </tr>
                            )}
                        </tbody>
                    </Table>
                </Card>
            </Content>
        </>
    )
}

export default Component