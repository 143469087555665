import styled from 'styled-components'

export const Container = styled.div`
  max-width: 100%;
  overflow-x: auto;
`

export const Table = styled.table`
  width: 100%;
  padding: 0 15px 15px;
  & th {
    padding: 12px;
    text-align: left;
    border-bottom: 1px solid var(--color-grey);
  }
  & tr:nth-child(even) {
    background-color: var(--color-background-input);
  }
  & td {
    padding: 8px;
  }
`

export const TdWithTextLimit = styled.td`
  display: block;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: ${props => `${props.maxWidth || 200}px`};
`
