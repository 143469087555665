import { useEffect, useState } from 'react'
import { useHistory, useParams } from 'react-router-dom'
import { useFormik } from 'formik'
import * as Yup from 'yup'
import Validation from './validation'
import {
  handleUserShow,
  handleUpdateUser,
  handleUserDelete,
} from 'services/api/users'
import Component from './Component'

const Page = () => {
  const history = useHistory()
  const params = useParams()
  const [messages, setMessages] = useState({ messages: [], alert: '' })
  const [data, setData] = useState({})
  const [visibleModal, setVisibleModal] = useState(false)

  useEffect(() => {
    ;(async () => setData(await handleUserShow(params.id)))()
  }, [params.id])

  const formik = useFormik({
    initialValues: {
      name: data.name,
      email: data.email,
      roles: data.roles,
      contact: data.contact || '',
      value: String(data?.value?.toFixed(2) || '0'),
      status_id: data.status_id || '',
      origin_id: data.origin_id || '',
      company_id: data.company_id || '',
      text_message: data.text_message || false,
      voice_message: data.voice_message || false,
    },
    validationSchema: Yup.object(Validation(Yup)),
    onSubmit: () =>
      handleUpdateUser(history, formik.values, params.id, setMessages),
    enableReinitialize: true,
  })

  const handleDelete = async () => await handleUserDelete(params.id, history)

  return (
    <Component
      setVisibleModal={setVisibleModal}
      visibleModal={visibleModal}
      formik={formik}
      messages={messages}
      history={history}
      id={data.id}
      handleDelete={handleDelete}
    />
  )
}

export default Page
