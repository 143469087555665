import { createContext, useState } from 'react'

import { PublicClientApplication } from '@azure/msal-browser'

import { msalConfig, loginRequest } from '../config/azure'

export const Context = createContext()

const msalInstance = new PublicClientApplication(msalConfig);

const ContextProvider = ({ children }) => {
    const [toggle, setToggle] = useState(true)

    const [user, setUser] = useState(null)
    const [isLoaded, setIsLoaded] = useState(false)

    const [report, setReport] = useState()

    const signIn = async () => {
      const response = await msalInstance.loginPopup(
        {
          scopes: loginRequest.scopes,
          prompt: "select_account"
        })

        console.log(response.idToken)

        return response.idToken
    }

    return (
      <Context.Provider value={{ 
        toggle, 
        setToggle, 
        user, 
        setUser,
        signIn,
        report,
        setReport,
        isLoaded,
        setIsLoaded
      }}>
        {children}
      </Context.Provider>
    )
}

export default ContextProvider