import BtnBlue from 'components/Button/BtnBlue'
import BtnBox from 'components/Button/BtnBox'
import BtnRed from 'components/Button/BtnRed'
import BtnWhite from 'components/Button/BtnWhite'
import Card from 'components/Card'
import CardBody from 'components/CardBody'
import CardTitle from 'components/CardTitle'
import Content from 'components/Content'
import Input from 'components/Form/LabelInput'
import Upload from 'components/Form/Upload'
import Messages from 'components/Messages'
import Modal from 'components/Modal'
import Spinner from 'components/Spinner'
import TitleBar from 'components/TitleBar'
import { useFormik } from 'formik'
import JoditEditor from 'jodit-react'
import { useEffect, useRef, useState } from 'react'
import { useHistory, useParams } from 'react-router-dom'
import {
  handleCardDelete,
  handleCardReport,
  handleCardShow,
  handleCardUpdateFile
} from 'services/api/reportCards'
import * as Yup from 'yup'
import Validation from './validation'

import { getToken, getUniqueID } from 'services/auth'
import conf from '../../config'

const Page = () => {
  const editor = useRef(null);
  const [content, setContent] = useState('')
  const history = useHistory()
  const params = useParams()
  const [messages, setMessages] = useState({ messages: [], alert: '' })
  const [data, setData] = useState({})
  const [visibleModal, setVisibleModal] = useState(false)

  const [messagesFile, setMessagesFile] = useState('')
  const [done, setDone] = useState(true)
  const [errors, setErrors] = useState([])

  useEffect(() => {
    (async () => {
      const res = await handleCardShow(params.id)
      setData(res)
      setContent(res.content)
      setDone(false)
    })()
  }, [params.id])

  const formik = useFormik({
    initialValues: {
      name: data.name,
      email: data.email,
      roles: data.roles,
      group_id: data.group_id,
      report_id: data.report_id,
      dataset_id: data.dataset_id,
      category_id: data.category_id ? String(data.category_id) : '',
      tipo: String(data.tipo)
    },
    validationSchema: Yup.object(Validation(Yup)),
    onSubmit: () => handleCardReport(history, { ...formik.values, content }, params.id, setMessages),
    enableReinitialize: true
  })

  const files = async files => {
    setMessagesFile('')
    setErrors([])
    setDone(true)
    await handleCardUpdateFile(files[0], params.id)
    setData(await handleCardShow(params.id))
    setDone(false)
  }

  const handleDelete = async () => await handleCardDelete(params.id, history)

  return (
    <>
      <TitleBar label="Cards" currentPage="" />
      <Content>
        <Card>
          <CardTitle title="Editar">
            <BtnRed onClick={() => setVisibleModal(true)}>Excluir</BtnRed>
          </CardTitle>
          <Content>
            <CardBody>
              <form onSubmit={formik.handleSubmit}>
                <Messages formMessages={messages.messages} alert={messages.alert} />
                <Input name="name" label="Nome" formik={formik} />

                {data.tipo === 1 && (
                  <>
                    <div style={{ height: "15px", width: "10px" }} />

                    <JoditEditor
                      ref={editor}
                      value={content}
                      config={{
                        readonly: false,
                        language: 'pt_br',
                        uploader: {
                          insertImageAsBase64URI: false,
                          url: `${conf.envApi}/report-cards/upload`,
                          headers: {
                            Authorization: `${getToken()}`,
                            uniqueId: `${getUniqueID()}`
                          },
                          format: 'json',

                          isSuccess: function (resp) {
                            return resp.success;
                          },
                          getMessage: function (resp) {
                            return resp.msg;
                          },
                          process: function (resp) {
                            const url = resp.file.isImage ? `${conf.envApi}/public/media/${resp.file.filename}` : `${conf.envApi}/report-cards/downloads/${resp.file.filename}`
                            return {
                              files: [url],
                              path: resp.path,
                              isImages: [resp.file.isImage],
                              error: resp.error,
                              messages: [resp.message]
                            };
                          },
                          defaultHandlerSuccess: function (data, resp) {
                            if (data.isImages[0]) {
                              this.s.insertImage(data.files[0])
                            } else {
                              const downloadLink = document.createElement('a');
                              downloadLink.href = data.files[0];
                              downloadLink.download = 'nome-do-arquivo'; // Você pode definir um nome para o arquivo aqui
                              downloadLink.innerText = 'Clique aqui para baixar o arquivo'; // Texto do link de download
                              this.s.insertNode(downloadLink);
                            }
                          },
                          error: function (e) {
                            this.message.message(e.getMessage(), 'error', 4000);
                          }
                        },
                      }}
                      tabIndex={1} // tabIndex of textarea
                      onBlur={newContent => setContent(newContent)} // preferred to use only editor option to update the content for performance reasons
                      onChange={newContent => { }}
                    />
                  </>
                )}
                <BtnBox>
                  <BtnBlue type="submit" disabled={formik.isSubmitting}>
                    {formik.isSubmitting ? <Spinner /> : "Enviar"}
                  </BtnBlue>
                </BtnBox>
              </form>
            </CardBody>
          </Content>
        </Card>

        <Card>
          <CardTitle title="Imagem"></CardTitle>
          <Content>
            <CardBody>
              <Upload
                onUpload={files}
                fileUrl={data.file_url}
                done={done}
                errors={errors}
                setErrors={setErrors}
              />
              <p>{messagesFile}</p>
            </CardBody>
          </Content>
        </Card>

      </Content>
      <Modal visibleModal={visibleModal} setVisibleModal={setVisibleModal}>
        <Card>
          <Content>
            <Content>
              <CardBody>
                <p>Deseja excluir esse item?</p>
                <BtnBox>
                  <BtnRed onClick={handleDelete}>Excluir</BtnRed>
                  <BtnWhite onClick={() => setVisibleModal(false)}>Cancelar</BtnWhite>
                </BtnBox>
              </CardBody>
            </Content>
          </Content>
        </Card>
      </Modal>
    </>
  )
}

export default Page
