import styled from 'styled-components'

import { Link } from 'react-router-dom'

import { CDropdown, CDropdownToggle, CDropdownMenu, CDropdownItem } from "@coreui/react"

export const Topbar = styled.header`
    height: 60px;
    width: 100%;
    position: fixed;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 2rem;
    z-index: 1;
    background-color: ${props => props.color};
    border-bottom: 1px solid var(--color-grey);
    box-shadow: 2px 2px 5px 1px rgba(0, 0, 0, 0.2);

    @media (max-width: 600px) {
        padding: 0 1rem;
    } 
`

export const Logo = styled.img`
    height: 40px;

    @media (max-width: 350px) {
        width: 120px;
    } 
`

export const NavigationContainer = styled.div`
    display: flex;
    align-items: center;

    @media (max-width: 1200px) {
        display: none;
    } 
`

export const ShowMobile = styled.div`
    display: none;
    align-items: center;
    margin-left: 1rem;

    @media (max-width: 1200px) {
        display: block;
    } 
`

export const NavigationLink = styled(Link)`
    margin-left: 1rem;
    text-decoration: none;
    
    &:hover {
        opacity: .5;
    }
`

export const NavigationText = styled.span`
    margin-left: .5rem;
    text-decoration: none !important;
    color: var(--color-orange);

    @media (max-width: 1200px) {
        display: ${props => props.show ? "block" : "none"};
    } 
`

export const LeftSideContainer = styled.div`
    display: flex;
    align-items: center;
`

export const RightSideContainer = styled.div`
    display: flex;
    align-items: center;
`

export const ActionsContainer = styled.div`
    display: flex;
    align-items: center;
`

export const Divider = styled.div`
    height: 25px;
    width: .5px;
    margin: 0 1rem;
    border-radius: 100%;
    background-color: var(--color-orange);

    @media (max-width: 600px) {
        margin: 0 1rem;
    } 

    @media (max-width: 350px) {
        margin: 0 .5rem;
    } 
`

export const Dropdown = styled(CDropdown)``

export const DropdownToggle = styled(CDropdownToggle)`
    background-color: transparent !important;
    border-radius: 30px;
    border: none;
    transition: all 0.3s;
    padding: 0;

    display: flex;
    justify-content: center;
    align-items: center;

    font-size: 18px;
    font-weight: bold;

    &:hover {
        opacity: 0.5;
    }

    &:focus {
        outline: 0;
    }

    @media (max-width: 600px) {
        font-size: 15px !important;
    } 

    &.dropdown-toggle::after {
        color: ${props => props.color};
    }
`

export const DropdownMenu = styled(CDropdownMenu)`
    transform: translate(${props => props.x}, ${props => props.y}) !important;
`

export const DropdownItem = styled(CDropdownItem)`
    background-color: white !important;
    cursor: pointer;

    &:hover {
        opacity: .5;
    }
`

export const DropdownText = styled.span`
    margin-left: .5rem;
    color: var(--color-orange);
`

export const UserContainer = styled.div`
    display: flex;
    align-items: center;
    border-radius: 8px;
    cursor: pointer;
`

export const UserName = styled.h6`
    display: flex;
    align-items: center;
    height: 2rem;
    padding-right: .5rem;
    font-weight: 600;
    color: var(--color-orange);

    @media (max-width: 630px) {
        display: none;
    } 
`

export const UserPhoto = styled.img`
    height: 40px;
    width: 40px;
    border-radius: 100%;
    margin-right: .5rem;
`

// export const TopbarMenu = styled.div`
//     display: flex;
//     justify-content: center;
//     align-items: center;
//     color: var(--color-white);
//     &:hover {
//         cursor: pointer;
//         /* opacity: 0.5; */
//     }
// `
// export const Topbar = styled.header`
//     position: fixed;
//     height: 60px;
//     width: 100%;
//     background-color: #fff;
//     box-shadow: 2px 2px 5px 1px rgba(0, 0, 0, 0.2);
//     border-bottom: 1px solid var(--color-grey);
//     display: flex;
//     justify-content: space-between;
//     align-items: center;
//     z-index: 1;
//     @media (max-width: 1140px) {
//         position: fixed;
//     }
// `
// export const TopbarMenuDesktop = styled(TopbarMenu)`
//     transition: all ease 0.2s;
//     height: inherit;
//     & span {
//         margin-left: 20px;
//         font-size: 25px;
//         color: var(--color-sidebar);
//         display: flex;
//     }
//     @media (max-width: 1140px) {
//         & {
//             display: none;
//         }
//     }
// `
// export const TopbarMenuMobile = styled(TopbarMenu)`
//     transition: all ease 0.2s;
//     & span {
//         color: var(--color-orange);
//         font-size: 18pt;
//         transition: all ease 0.2s;
//         padding-left: 20px;
//     }

//     &.menu-open {
//         margin-left: 180px;
//     }

//     @media (max-width: 355px) {
//         &.menu-open {
//             margin-right: 290px;
//         }
//     }

//     @media (max-width: 2562px) {
//         & {
//             display: none;
//         }
//     }
//     @media (max-width: 1140px) {
//         & {
//             display: flex;
//         }
//     }
// `
// export const Logout = styled.button`
//     margin: 0 30px;
//     padding: 3px 25px;
//     border-radius: 30px;
//     border: none;
//     color: var(--color-000);
//     font-size: 14px;
//     background-color: var(--color-green);
//     &:hover {
//         cursor: pointer;
//         opacity: 0.5;
//     }
// `
// export const TopbarActions = styled.div`
//     display: flex;
//     align-items: center;
// `
// export const TopbarLinks = styled.a`
//     height: 60px;
//     display: flex;
//     align-items: center;
//     text-decoration: none;
//     color: var(--color-sidebar);
//     font-weight: bold;
//     padding: 0 15px;
//     & img {
//         height: 30px;
//         margin-right: 10px;
//     }
// `