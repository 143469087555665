import { setErrors } from 'utils'
import api from './api'

export const handlePowerBiGroups = async () =>
  (await api.get(`/powerbi/groups`)).data

export const handlePowerBiReports = async id =>
  (await api.get(`/powerbi/groups/${id}/reports`)).data

export const handlePowerBiReportAad = async values =>
  (await api.post(`/powerbi/reports/aad`, values)).data

export const handlePowerBiReportEmbed = async values =>
  (await api.post(`/powerbi/reports/embed`, values)).data

export const handlePowerBiExportReport = async values =>
  (
    await api.post(`/powerbi/reports/exportTo`, values, {
      responseType: 'blob',
    })
  ).data

export const handleRefreshPowerBiCapacities = async () =>
  (await api.post(`/powerbi/capacities/refresh`)).data

export const handleUpdatePowerBiCapacity = async (
  id,
  data,
  setMessages,
  history,
) => {
  try {
    await api.put(`/powerbi/capacities/${id}`, data)

    return history.push('/capacidades')
  } catch (error) {
    setMessages({ messages: setErrors(error), alert: 'alert-orange' })
  }
}

export const handleUpdateOrCreatePowerBiCapacityIdleIntervals = async (
  id,
  data,
  setMessages,
) => {
  try {
    await api.post(`/powerbi/capacities/${id}/idle-intervals`, data)
    setMessages({
      messages: 'Intervalo salvo com sucesso',
      alert: 'alert-green',
    })
  } catch (error) {
    setMessages({ messages: setErrors(error), alert: 'alert-orange' })
  }
}

export const handleGetPowerBiCapacity = async id =>
  (await api.get(`/powerbi/capacities/${id}`)).data

export const handleGetPowerBiCapacities = async () =>
  (await api.get(`/powerbi/capacities`)).data

export const handleResumePowerBiCapacity = async id =>
  (await api.post(`/powerbi/capacities/${id}/resume`)).data

export const handleSuspendPowerBiCapacity = async id =>
  (await api.post(`/powerbi/capacities/${id}/suspend`)).data
