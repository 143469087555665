import api from './api'
import { setErrors } from 'utils'

export const handleCompanies = async () => (await api.post(`/companies/list-by-firm`)).data

export const handleCompanieshow = async id => (await api.get(`/companies/${id}`)).data

export const handleCompanieshowSlug = async slug => (await api.get(`/companies/list-by-slug/${slug}`)).data

export const handleCompaniesStore = async (history, data, setMessages) => {
  try {
    const response = await api.post(`/companies/store`, data)
    return history.push(`/empresas/${response.data.id}/update`)
  } catch (error) {
    setMessages({ messages: setErrors(error), alert: 'alert-orange' })
  }
}

export const handleCompanyUpdate = async (history, data, cardId, setMessages) => {
  try {
    await api.put(`/companies/${cardId}/update`, data)
    return history.push(`/empresas`)
  } catch (error) {
    setMessages({ messages: setErrors(error), alert: 'alert-orange' })
  }
}

export const handleCompanyDelete = async (id, history) => {
  await api.delete(`/companies/${id}`)
  return history.push(`/empresas`)
}

export const handleCompanyUpdateLogo = async (data, id) => {
  const values = new FormData()
  values.append('file', data)
  try {
    await api.put(`/companies/${id}/upload/logo`, values)
    return
  } catch (error) {
    return error
  }
}

export const handleCompanyUpdateBackground = async (data, id) => {
  const values = new FormData()
  values.append('file', data)
  try {
    await api.put(`/companies/${id}/upload/background`, values)
    return
  } catch (error) {
    return error
  }
}