import { useContext } from 'react'

import { Context } from 'contexts/context'

import { LinkBlue } from './styled'

const Component = props => {
    const { user } = useContext(Context)

    return (
        <LinkBlue color={user?.company?.secondary_color} to={props.to}>
            {props.children}
        </LinkBlue>
    )
}

export default Component