import { useContext, useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'

import { handleCategoriesByUser } from 'services/api/categories'

import { Context } from 'contexts/context'

import Card from 'components/Card'
import CardTitle from 'components/CardTitle'
import Container from 'components/Container'
import Content from 'components/Content'
import ReportCard from 'components/ReportCard'
import TitleBar from 'components/TitleBar'

const Page = () => {
  const [data, setData] = useState([])

  const { user } = useContext(Context)

  const params = useParams()

  useEffect(() => {
    (async () => {
      try {
        setData(await handleCategoriesByUser())
      } catch(error) {}
    })()
  }, [params.group_id, user.roles])

  return (
    <>
      <TitleBar label="Categorias" currentPage="" />

      <Content>
        <Card>
          <CardTitle title="Minhas categorias" />

          <Container>
              {data.map(item => <ReportCard key={item.id} name={item.name} fileUrl={item.file_url} linkTo={`/home/${item.id}`} />)}
            </Container>
        </Card>
      </Content>
    </>
  )
}

export default Page