import styled from 'styled-components'

export const IdleIntervalContainer = styled.div`
  margin-top: 15px;
  display: flex;
  flex-direction: column;
  row-gap: 10px;
`

export const IdleIntervalItemsContainer = styled.div`
  display: flex;
  align-items: start;
  column-gap: 20px;

  .react-datepicker-wrapper {
    width: fit-content;
  }
`

export const IdleIntervalItemContainer = styled.div`
  display: flex;
  flex-direction: column;

  column-gap: 20px;

  span {
    font-size: 14px;
    color: var(--color-red);
  }
`
