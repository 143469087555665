import { useFormik } from 'formik'
import { useEffect, useState } from 'react'
import { useHistory, useParams } from 'react-router-dom'
import {
  handleGetPowerBiCapacity,
  handleUpdatePowerBiCapacity,
} from 'services/api/powerbi'
import * as Yup from 'yup'
import Component from './Component'
import Validation from './validation'

const Page = () => {
  const history = useHistory()
  const params = useParams()
  const [messages, setMessages] = useState({ messages: [], alert: '' })
  const [data, setData] = useState({})

  useEffect(() => {
    ;(async () => setData(await handleGetPowerBiCapacity(params.id)))()
  }, [params.id])

  const formik = useFormik({
    initialValues: {
      resource_group: data.resource_group,
      subscription_id: data.subscription_id,
    },
    validationSchema: Yup.object(Validation(Yup)),
    onSubmit: () =>
      handleUpdatePowerBiCapacity(
        params.id,
        formik.values,
        setMessages,
        history,
      ),
    enableReinitialize: true,
  })

  return (
    <Component
      formik={formik}
      messages={messages}
      history={history}
      data={data}
      id={data.id}
    />
  )
}

export default Page
