import { useState } from 'react'
import { useHistory } from 'react-router-dom'
import { useFormik } from 'formik'

import * as Yup from 'yup'

import TitleBar from 'components/TitleBar'
import Content from 'components/Content'
import Card from 'components/Card'
import CardTitle from 'components/CardTitle'
import Input from 'components/Form/LabelInput'
import BtnBlue from 'components/Button/BtnBlue'
import ColorPicker from 'components/Form/ColorPicker'
import Messages from 'components/Messages'
import Spinner from 'components/Spinner'
import CardBody from 'components/CardBody'
import BtnBox from 'components/Button/BtnBox'

import { handleCompaniesStore } from 'services/api/empresas'

import { slugMask } from 'utils'

const validation = yup => ({
    name: yup.string()
        .required('Campo obrigatório'),
    slug: yup.string()
      .required('Campo obrigatório'),
    email: yup.string()
      .required('Campo obrigatório')
})

const NewReportCollection = () => {
    const [messages, setMessages] = useState({ messages: [], alert: '' })

    const history = useHistory()

    const formik = useFormik({
      initialValues: { 
        name: '', 
        slug: '', 
        email: '', 
        primary_color: '#ffffff', 
        secondary_color: '#ffffff'
      },
      validationSchema: Yup.object(validation(Yup)),
      onSubmit: async () => handleCompaniesStore(history, formik.values, setMessages)
    })

    return (
        <>
        <TitleBar label="Empresas" currentPage="" />

        <Content>
          <Card>
            <CardTitle title="Cadastrar"></CardTitle>

            <Content>
              <CardBody>
                <form onSubmit={formik.handleSubmit}>
                  <Messages formMessages={messages.messages} alert={messages.alert} />

                  <Input name="name" label="Nome" formik={formik} />
                  <Input mask={slugMask} name="slug" label="Slug" formik={formik} />
                  <Input name="email" label="email" formik={formik} />

                  <ColorPicker name="primary_color" label="Cor primária" formik={formik} />
                  <ColorPicker name="secondary_color" label="Cor secundária" formik={formik} />

                  <BtnBox>
                    <BtnBlue type="submit" disabled={formik.isSubmitting}>
                      {formik.isSubmitting ? <Spinner /> : "Enviar"}
                    </BtnBlue>
                  </BtnBox>
                </form>
              </CardBody>
            </Content>
          </Card>
        </Content>
      </>
    )
}

export default NewReportCollection