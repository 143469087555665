import { useEffect, useState } from 'react'
import { FaEdit } from "react-icons/fa"
import { HiOutlineDocumentReport } from "react-icons/hi"
import { useFormik } from 'formik'

import TitleBar from 'components/TitleBar'
import Content from 'components/Content'
import Card from 'components/Card'
import CardTitle from 'components/CardTitle'
import Input from 'components/Form/LabelInput'
import Table from 'components/Table'
import LinkBlue from 'components/Button/LinkBlue'
import SmLinkBlue from 'components/Button/SmLinkBlue'

import Td from 'components/Td'

import api from 'services/api/api'

export default function ReportsCollection() {
    const [reportsCollections, setReportsCollections] = useState([])
    const [list, setList] = useState([])
    
    const formik = useFormik({
        initialValues: {
        search: ''
        }
    })

    useEffect(() => {
        (async () => {
            try {
                const { data } = await api.get(`/collections/by-firm`)

                setReportsCollections(data)
            } catch(error) {

            }
          })()
    }, [])

    useEffect(() => {
        setList(reportsCollections.filter(item => item.name.toUpperCase().includes(formik.values.search.toUpperCase())))
    }, [formik.values.search, reportsCollections])

    return (
        <>
        <TitleBar label="Grupos" currentPage="" />

        <Content>
            <Card>
                <CardTitle title="Listagem">
                    <LinkBlue to="/colecoes/cadastrar">Cadastrar</LinkBlue>
                </CardTitle>

                <div style={{ padding: "15px 15px 0 15px" }}>
                    <Input
                    placeholder="Buscar..."
                    name="search"
                    label=""
                    formik={formik}
                    />
                </div>

                <Table>
                    <thead>
                        <tr>
                            <th width="60">#</th>
                            <th>Nome</th>
                            <th width="100">Ações</th>
                        </tr>
                    </thead>
                    <tbody>
                        {list.map(reportsCollection =>
                            <tr key={reportsCollection.id}>
                                <td>{reportsCollection.id}</td>
                                <td>{reportsCollection.name}</td>

                                <Td>
                                    <SmLinkBlue to={`/colecoes/${reportsCollection.id}/editar`}>
                                        <FaEdit />
                                    </SmLinkBlue>

                                    <SmLinkBlue to={`/colecoes/${reportsCollection.id}/relatorios`}>
                                        <HiOutlineDocumentReport />
                                    </SmLinkBlue>
                                </Td>
                            </tr>
                        )}
                    </tbody>
                </Table>
            </Card>
        </Content>
    </>
    )
}