import { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'

import { handleCardShow } from 'services/api/reportCards'

const Page = () => {
  const [data, setData] = useState()

  const params = useParams()

  useEffect(() => {
    (async () => {
      try {
        setData(await handleCardShow(params.id))
      } catch (error) { }
    })()
  }, [params.id])

  console.log(data)

  return (
    <>
      {data && <div style={{}} dangerouslySetInnerHTML={{ __html: data.content }} />}
    </>
  )
}

export default Page