import { Label, CheckboxLabel } from './styled'

const Component = ({ name, label, formik, checked, ...rest }) => {
  return (
    <CheckboxLabel>
      <input
        type="checkbox"
        id={name}
        name={name}
        checked={formik.values[name]}
        onChange={formik.handleChange}
        defaultChecked={checked}
        {...rest}
      />
      <Label>
        <label htmlFor={name}>{label}</label>
        <div>
          {formik.touched[name] && formik.errors[name]
            ? formik.errors[name]
            : null}
        </div>
      </Label>
    </CheckboxLabel>
  )
}

export default Component
