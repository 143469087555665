import { useEffect, useState } from 'react'
import { useParams } from 'react-router'
import { RiSubtractLine } from "react-icons/ri"

import TitleBar from 'components/TitleBar'
import Content from 'components/Content'
import Card from 'components/Card'
import CardTitle from 'components/CardTitle'
import Table from 'components/Table'
import LinkBlue from 'components/Button/LinkBlue'
import BtnOrange from 'components/Button/BtnOrange'
import BtnRed from 'components/Button/BtnRed'
import BtnBox from 'components/Button/BtnBox'
import CardBody from 'components/CardBody'
import BtnWhite from 'components/Button/BtnWhite'
import Modal from 'components/Modal'

import { handleUserShow } from 'services/api/users'

import api from 'services/api/api'

const Page = () => {
    const [user, setUser] = useState({})

    const [userAccess, setUserAcess] = useState([])
    const [visibleModal, setVisibleModal] = useState(false)

    const [userAccessSelectedId, setUserAccessSelectedId] = useState()

    const params = useParams()

    useEffect(() => {
        (async () => {
            setUser(await handleUserShow(params.id))
        })()
    }, [params.id])

    useEffect(() => {
        loadUserReportsCollections()
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const loadUserReportsCollections = async () => {
        try {
            const { data } = await api.get(`/users-access/${params.id}`)

            setUserAcess(data)
        } catch(error) {

        }
    }

    const handleDelete = async () => {
        try {
            await api.delete(`/users-access/${userAccessSelectedId}`)

            loadUserReportsCollections()
            setVisibleModal(false)
        } catch(error) {
        }
    }

    return (
        <>
            <TitleBar label="Usuários" currentPage="" />

            <Content>
                <Card>
                    <CardTitle title="Usuário" />

                    <Table>
                        <thead>
                        <tr>
                            <th>Nome</th>
                            <th>Email</th>
                            <th>Permissão</th>
                        </tr>
                        </thead>
                        <tbody>
                        <tr>
                            <td>{user.name}</td>
                            <td>{user.email}</td>
                            <td>{user.roles}</td>
                        </tr>
                        </tbody>
                    </Table>
                </Card>

                <Card>
                    <CardTitle title="Acessos">
                        <LinkBlue to={`/usuarios/${params.id}/acessos/cadastrar`}>Cadastrar</LinkBlue>
                    </CardTitle>

                    <Table>
                        <thead>
                            <tr>
                                <th width="60">#</th>
                                <th>De</th>
                                <th>Até</th>
                                <th width="50">Ações</th>
                            </tr>
                        </thead>

                        <tbody>
                            {userAccess.map(userReportsCollection => (
                                <tr key={userReportsCollection.id}>
                                    <td>{userReportsCollection.id}</td>
                                    <td>{userReportsCollection.initial_date}</td>
                                    <td>{userReportsCollection.end_date}</td>

                                    <td >
                                        <BtnOrange style={{ padding: '5px 20px' }} onClick={() => {
                                            setVisibleModal(true)
                                            setUserAccessSelectedId(userReportsCollection.id)
                                        }}>
                                            <RiSubtractLine />
                                        </BtnOrange>
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </Table>
                </Card>
            </Content>

            <Modal visibleModal={visibleModal} setVisibleModal={setVisibleModal}>
                <Card>
                    <Content>
                    <Content>
                        <CardBody>
                        <p>Deseja remover esta coleção?</p>

                        <BtnBox>
                            <BtnRed onClick={handleDelete}>Excluir</BtnRed>
                            <BtnWhite onClick={() => setVisibleModal(false)}>Cancelar</BtnWhite>
                        </BtnBox>
                        </CardBody>
                    </Content>
                    </Content>
                </Card>
            </Modal>
        </>
    )
}

export default Page
