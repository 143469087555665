import { useContext } from 'react'

import { Context } from 'contexts/context'

import { BtnBlue } from './styled'

const Component = props => {
    const { user } = useContext(Context)

    return (
        <BtnBlue color={user?.company?.secondary_color} {...props} type={props.type} onClick={props.onClick}>
            {props.children}
        </BtnBlue>
    )
}

export default Component