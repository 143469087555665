import BtnBlue from 'components/Button/BtnBlue'
import BtnBox from 'components/Button/BtnBox'
import Card from 'components/Card'
import CardBody from 'components/CardBody'
import Content from 'components/Content'
import Input from 'components/Form/LabelInput'
import Messages from 'components/Messages'
import Spinner from 'components/Spinner'
import TitleBar from 'components/TitleBar'

const Component = props => {
  return (
    <>
      <TitleBar label={`Capacidade - ${props.id}`} currentPage="" />
      <Content>
        <Card>
          <Content>
            <CardBody>
              <form onSubmit={props.formik.handleSubmit}>
                <Messages
                  formMessages={props.messages.messages}
                  alert={props.messages.alert}
                />
                <Input
                  name="id"
                  label="ID"
                  readOnly
                  disabled
                  value={props.data?.capacity_id}
                  formik={props.formik}
                />
                <Input
                  name="name"
                  label="Nome"
                  readOnly
                  value={props.data?.name}
                  formik={props.formik}
                />
                <Input
                  name="sku"
                  label="SKU"
                  readOnly
                  value={props.data?.sku}
                  formik={props.formik}
                />
                <Input
                  name="status"
                  label="Status"
                  readOnly
                  value={props.data?.state}
                  formik={props.formik}
                />
                <Input
                  name="resource_group"
                  label="Nome do grupo de recursos"
                  formik={props.formik}
                />
                <Input
                  name="subscription_id"
                  label="ID da subscrição"
                  formik={props.formik}
                />
                <BtnBox>
                  <BtnBlue type="submit" disabled={props.formik.isSubmitting}>
                    {props.formik.isSubmitting ? <Spinner /> : 'Enviar'}
                  </BtnBlue>
                </BtnBox>
              </form>
            </CardBody>
          </Content>
        </Card>
      </Content>
    </>
  )
}

export default Component
